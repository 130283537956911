.promo {
  background-color: #073042;
  
  padding: 70px 0 30px;
}

.promo__container {
  width: calc(100% - (10px * 2));
  max-width: 680px;
  
  margin: 0 auto;
}

.promo__cover-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  margin-bottom: 80px;
}

.promo__cover-image {
  width: clamp(210px, 50vw, 310px);
  aspect-ratio: 105 / 103;
  
  background-image: url('../../images/lead-cover.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  
  display: block;
  order: 1;
  
  margin: 0 auto;
}

.promo__title-container {
  display: flex;
  flex-direction: column;
  order: 2;
  row-gap: 24px;
  align-items: center;
  
  margin: 44px auto 0;
}

.promo__title {
  width: 100%;
  max-width: 680px;
  
  font-size: clamp(30px, 7vw, 40px);
  font-weight: 400;
  line-height: clamp(38px, 9vw, 50px);
  text-align: center;
  letter-spacing: -1.2px;
  
  margin: 0;
}

.promo__subtitle {
  width: calc(100% - (20px * 2));
  max-width: 260px;
  
  color: #8397a0;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  
  margin: 0;
}

.promo__link {
  max-width: 129px;
  max-height: 36px;
  box-sizing: border-box;
  
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  text-decoration: none;
  
  opacity: 1;
  
  display: block;
  
  margin: 0 auto;
  border: 1px solid #8397a0;
  border-radius: 3px;
  padding: 10.5px;
  
  transition: opacity .4s ease-out;
}

.promo__link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .promo {
    padding: 140px 0 76px;
  }
  
  .promo__container {
    width: calc(100% - (44px * 2));
    max-width: 1140px;
  }
  
  .promo__cover-container {
    margin-bottom: 92px;
  }
  
  .promo__title-container {
    row-gap: 22px;
    
    margin-top: 48px;
  }
  
  .promo__title {
    font-size: clamp(40px, 4.5vw, 50px);
    line-height: clamp(50px, 5.5vw, 58px);
    letter-spacing: 0;
  }
}

@media (min-width: 1280px) {
  .promo {
    padding: 75px 0;
  }
  
  .promo__container {
    width: calc(100% - (70px * 2));
  }
  
  .promo__cover-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    
    margin-bottom: 84px;
  }
  
  .promo__cover-image {
    margin: 0;
  }
  
  .promo__title-container {
    order: 1;
    row-gap: 36px;
    align-items: start;
    
    margin: 0;
  }
  
  .promo__title {
    max-width: 640px;
    
    text-align: left;
  }
  
  .promo__subtitle {
    text-align: left;
  }
  
  .promo__cover-image {
    order: 2;
  }
  
  .promo__link {
    margin: 0;
  }
}