@keyframes appear {
  from {
    transform: translate(100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

.appear {
  animation: appear .2s forwards ease-in;
}

@keyframes disappear {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}

.disappear {
  animation: disappear .2s forwards ease-in;
}

.burger-menu__base {
  width: 100%;
  height: 100vh;
  
  visibility: hidden;
  background-color: #000;
  opacity: .3;
  
  position: fixed;
  top: 0;
  right: 0;
  
  transition: opacity .2s ease-out;
}

.burger-menu__base_hidden {
  visibility: hidden;
}

.burger-menu__base_visible {
  visibility: visible;
  
  transition: all .2s ease-in;
}

.burger-menu__container {
  width: 100%;
  max-width: 520px;
  height: 100vh;
  
  visibility: hidden;
  background-color: #202020;
  opacity: 1;
  display: grid;
  
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  
  transform: translate(100%, 0);
}

.burger-menu__container_visible {
  visibility: visible;
}

.burger-menu__nav {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  margin: 144px auto 48px;
}

.burger-menu__nav-container {
  list-style: none;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  align-items: center;
  
  margin: 0 0 24px 0;
  padding: 0;
}

.burger-menu__link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  
  opacity: 1;
  
  position: relative;
  
  border: 2px solid rgba(255, 255, 255, 0);
  padding-bottom: 7px;
  
  transition: all .4s ease-out;
}

.burger-menu__link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

.burger-menu__link_active {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  
  transition: border-bottom-color .2s ease-in;
}

@media (min-width: 768px) {
  .burger-menu__navigation {
    margin: 159px 28px 92px auto;
  }
  
  .burger-menu__navigation-container {
    row-gap: 28px;
  }
}

@media (min-width: 1280px) {
  .burger-menu {
    display: block;
  }
}