.more-button {
  min-height: 10px;
  
  padding: 0;
}

.more-button__button {
  width: 100%;
  
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: #2f2f2f;
  
  opacity: 1;
  
  border: none;
  border-radius: 6px;
  padding: 10px 0;
  
  transition: opacity .4s ease-out;
}

.more-button__button:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .more-button {
    padding-bottom: 70px;
  }
}