.search-form {
  width: calc(100% - (14px * 2));
  max-width: 708px;
  
  margin: 0 auto 10px;
  border-bottom: #aaa solid 1px;
  padding: 70px 0 62px;
}

.search-form__search-query-container {
  position: relative;
}

.search-form__filter-container {
  display: flex;
  column-gap: 13px;
  
  margin-top: 30px;
}

.search-form__filter-name {
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  
  margin: 0;
}

.search-form__submit-button {
  min-width: 80px;
  
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  
  cursor: pointer;
  
  background-color: #4285f4;
  
  opacity: 1;
  
  position: absolute;
  
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 5px;
  padding: 13px 10px;
  
  transition: opacity .2s ease-out;
}

.search-form__submit-button:hover {
  opacity: .5;
  
  transition: opacity .2s ease-out;
}

@media (min-width: 768px) {
  .search-form {
    width: calc(100% - (30px * 2));
    max-width: 1140px;
    
    padding-bottom: 51px;
  }
  
  .search-form__search-query-input {
    padding: 23.5px 23.5px;
  }
  
  .search-form__filter-container {
    column-gap: 14px;
    
    margin-top: 26px;
  }
  
  .search-form__filter-name {
    font-size: 13px;
    
    margin: 0;
  }
  
  .search-form__submit-button {
    min-width: 100px;
    
    padding: 17px 20px;
  }
}

@media (min-width: 1280px) {
  .search-form {
    padding-bottom: 42px;
  }
}