.input {
  width: 100%;
  box-sizing: border-box;
  
  color: #fff;
  font-size: clamp(16px, 2.5vw, 18px);
  font-weight: 400;
  line-height: 16px;
  
  background-color: transparent;
  
  transition: none;
}

.input:focus {
  outline: none;
  border: 1.5px solid #8b8b8b;
  
  transition: none;
}

.input::placeholder {
  color: #8b8b8b;
}

.input_type_search {
  border: 1.5px solid #3c3c3c;
  border-radius: 8px;
  
  padding: 18.5px 12px;
}

@media (min-width: 768px) {
  .input {
    padding: 23.5px 23.5px;
  
    transition: all .2s ease-out;
  }
  
  .input:focus {
    transition: all .1s ease-in;
  }
}