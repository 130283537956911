.about-project {
  width: calc(100% - (18px * 2));
  max-width: 668px;
  
  margin: 0 auto;
  padding: 70px 0 69px;
}

.about-project__thesis-list {
  list-style: none;
  
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  
  margin: 60px 0;
  padding: 0;
}

.about-project__thesis-title {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  
  margin: 0;
}

.about-project__thesis-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  
  margin: 16px auto 0;
}

.about-project__stats {
  font-size: 11px;
  
  font-weight: 400;
  line-height: normal;
  text-align: center;
  list-style: none;
  
  display: grid;
  grid-template-columns: clamp(100px, 20vw, 140px) auto;
  grid-template-rows: auto auto;
  align-items: center;
  
  margin: 0 auto;
  padding: 0;
}

.about-project__cell-one {
  color: #000;
  
  background-color: #3ddc84;
  
  padding: 11px 0;
}

.about-project__cell-two {
  background-color: #303030;
  
  padding: 11px 0;
}

.about-project__caption {
  color: #a0a0a0;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  
  padding: 10px 0 0 0;
}

@media (min-width: 768px) {
  .about-project {
    width: calc(100% - (50px * 2));
    max-width: 1140px;
    
    padding: 90px 0;
  }
  
  .about-project__thesis-list {
    flex-direction: row;
    column-gap: 30px;
    
    margin: 70px 0 93px;
  }
  
  .about-project__thesis-item {
    min-width: 319px;
  }
  
  .about-project__thesis-title {
    font-size: 20px;
    letter-spacing: -0.8px;
  }
  
  .about-project__thesis-text {
    font-size: 12px;
    line-height: 18px;
    
    margin-top: 22px;
  }
  
  .about-project__stats {
    font-size: 14px;
    
    grid-template-columns: clamp(140px, 18vw, 228px) auto;
  }
  
  .about-project__cell-one {
    padding: 9px 0;
  }
  
  .about-project__cell-two {
    padding: 9px 0;
  }
  
  .about-project__caption {
    font-size: 14px;
    line-height: 17px;
    
    padding-top: 14px;
  }
}

@media (min-width: 1280px) {
  .about-project {
    padding: 110px 0;
  }
  
  .about-project__thesis-list {
    flex-direction: row;
    column-gap: 40px;
    
    margin-bottom: 110px;
  }
  
  .about-project__thesis-item {
    min-width: 550px;
  }
  
  .about-project__thesis-text {
    font-size: 14px;
    line-height: 20px;
    
    margin-top: 26px;
  }
  
  .about-project__caption {
    font-weight: 500;
  }
}