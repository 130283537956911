.footer {
  width: calc(100% - (10px * 2));
  max-width: 708px;
  
  margin: 0 auto;
  padding: 80px 0 20px;
}

.footer_hidden {
  display: none;
}

.footer__title {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 400;
  line-height: inherit;
  text-align: center;
  
  cursor: default;
  
  border-bottom: #424242 solid 1px;
  padding: 0 0 21px;
}

.footer__links-container {
  display: flex;
  flex-direction: column;
}

.footer__link-list {
  list-style: none;
  
  display: flex;
  flex-direction: column;
  order: 1;
  row-gap: 12px;
  align-items: center;
  
  margin: 30px auto;
  padding: 0;
}

.footer__link-list-item {
  line-height: 0;
}

.footer__link {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.footer__link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

.footer__copyright {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  
  cursor: default;
  
  order: 2;
  
  margin: 0;
}

@media (min-width: 768px) {
  .footer {
    width: calc(100% - (30px * 2));
    max-width: 1140px;
  }
  
  .footer__title {
    font-size: 13px;
    
    padding-bottom: 20px;
  }
  
  .footer__links-container {
    flex-direction: row;
    justify-content: space-between;
    
    margin-top: 20px;
  }
  
  .footer__link-list {
    flex-direction: row;
    order: 2;
    column-gap: 20px;
    
    margin: 0;
  }
  
  .footer__link {
    font-size: 13px;
  }
  
  .footer__copyright {
    color: #fff;
    font-size: 13px;
    
    order: 1;
  }
}