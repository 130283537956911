.home-link-with-logo {
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.home-link-with-logo:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}