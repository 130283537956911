/*noinspection CssUnknownProperty*/
@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

/*noinspection CssUnknownProperty*/
@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.preloader {
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 10;
}

.preloader_size_normal {
  height: 100%;
}

.preloader_size_all-screen {
  height: 100vh;
}

.preloader__container {
  width: 100px;
  height: 100px;
  
  position: relative;
  
  margin-top: 70px;
}

.preloader__container_size_all-screen {
  margin: 0;
}

.preloader__circle {
  width: 100px;
  height: 100px;
  
  background-color: #2f2f2f;
  
  position: absolute;
  
  top: 0;
  left: 0;
  border-radius: 50px;
}

.preloader__circle_color_bright {
  background-color: #3ddc84;
}

.preloader__circle::after,
.preloader__circle::before {
  content: '';
  
  width: 100px;
  height: 100px;
  
  position: absolute;
  
  top: 0;
  left: 0;
  border-radius: 50px;
}

.preloader__circle::after {
  background-color: #8b8b8b;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__circle_color_bright::after {
  background-color: #fff;
}

.preloader__circle::before {
  background-color: #2f2f2f;
  
  z-index: 10;
  
  animation: preloader-inside-red 1s ease-in-out infinite;
}

.preloader__circle_color_bright::before {
  background-color: #3ddc84;
}