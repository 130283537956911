.movies-card {
  line-height: 0;
  
  background-color: #222;
  
  flex: 1;
  
  border-radius: 6px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
}

.movies-card__details {
  width: calc(100% - (14px * 2));
  
  display: flex;
  justify-content: space-between;
  
  margin: 14px auto;
}

.movies-card__title {
  overflow: hidden;
  
  font-size: clamp(12px, 2.5vw, 15px);
  font-weight: 500;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  position: relative;
  
  margin: 0;
}

.movies-card__duration {
  min-width: 50px;
  
  color: #8b8b8b;
  font-size: clamp(10px, 2.5vw, 13px);
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  
  margin: 0;
}

.movies-card__image {
  width: 100%;
  aspect-ratio: 25 / 14;
  
  visibility: hidden;
  object-fit: cover;
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.movies-card__image:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

.movies-card__image_visible {
  visibility: visible;
}

@media (min-width: 768px) {
  .movies-card__details {
    width: calc(100% - (18px * 2));
    
    margin: 18px auto;
  }
  
  .movies-card__duration {
    min-width: 70px;
  }
}

@media (min-width: 1280px) {
  .movies-card {
    max-width: 340px;
  }
  
  .movies-card__image {
    max-height: 200px;
  }
}