.filter-checkbox {
  width: 36px;
  height: 20px;
  
  cursor: pointer;
  
  display: block;
  
  position: relative;
}

.filter-checkbox__original-checkbox {
  width: 1px;
  height: 1px;
  overflow: hidden;
  
  opacity: .1;
  clip-path: inset(0 0 0 0);
  
  position: absolute;
  
  margin: 10px auto 0 18px;
}

.filter-checkbox__custom-checkbox::before {
  content: '';
  
  width: 36px;
  height: 20px;
  
  background-color: #424242;
  
  position: absolute;
  
  top: 0;
  left: 0;
  border-radius: 20px;
  
  transition: all .2s ease-out;
}

.filter-checkbox__custom-checkbox:after {
  content: '';
  
  width: 8px;
  height: 8px;
  
  background-color: #fff;
  
  position: absolute;
  
  margin: 6px;
  border-radius: 50%;
  
  transition: all .2s ease-out;
}

.filter-checkbox__original-checkbox:checked ~ .filter-checkbox__custom-checkbox::before {
  background-color: #3ddc84;
  
  transition: background-color .2s ease-in;
}

.filter-checkbox__original-checkbox:checked ~ .filter-checkbox__custom-checkbox::after {
  transform: translate(16px, 0);
  
  transition: all .2s ease-in;
}