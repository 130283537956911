@keyframes jump-up {
  from {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, 1px);
  }
  to {
    transform: translate(0, 0);
  }
}

.jump-up {
  animation: jump-up .2s forwards ease-in-out;
}

.movies-card-button {
  width: 100px;
  height: 29px;

  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 11px;
  cursor: pointer;

  background-color: #313131;

  display: block;

  margin: 14px auto;
  border: none;
  border-radius: 30px;
  padding: 9px 15px;

  transition: background-color .4s ease-out;
}

.movies-card-button:hover {
  background-color: #ee3465;

  transition: background-color .2s ease-in;
}

.movies-card-button_active {
  background-color: #ee3465;
}

.movies-card-button__check-icon {
  width: 10px;
  height: 7px;

  background-image: url('../../images/check-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;

  margin: 0 auto;
  will-change: transform;
}

.movies-card-button__uncheck-icon {
  width: 10px;
  height: 7px;

  background-image: url('../../images/uncheck-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;

  margin: 0 auto;

  transition: opacity .4s ease-out;
  will-change: transform;
}

.movies-card-button__uncheck-icon:hover {
  opacity: .5;

  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .movies-card-button {
    margin: 18px auto;
  }
}