.close-button {
  width: 20px;
  height: 20px;
  
  cursor: pointer;
  
  background-color: transparent;
  background-image: url('../../../images/close-popup-pict.svg');
  background-repeat: no-repeat;
  background-size: contain;
  
  position: absolute;
  
  top: -35px;
  right: 0;
  border: none;
  
  transition: opacity .4s ease-out;
}

.close-button:hover {
  opacity: .6;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .close-button {
    width: 32px;
    height: 32px;
    
    top: -40px;
    right: -40px;
  }
}