.about-me {
  width: calc(100% - (14px * 2));
  max-width: 668px;
  
  margin: 0 auto;
  padding: 70px 0;
}

.about-me__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-me__photo {
  width: 100%;
  aspect-ratio: 73 / 88;
  
  object-fit: cover;
  
  display: block;
  order: 1;
  
  border-radius: 10px;
}

.about-me__bio {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: 2;
}

.about-me__bio-name {
  font-size: clamp(30px, 5vw, 40px);
  font-weight: 400;
  line-height: clamp(1, 5vw, 40px);
  letter-spacing: -1.2px;
  
  margin: 40px auto 20px;
}

.about-me__bio-details {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  
  margin: 20px auto;
}

.about-me__bio-text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  
  margin: 20px auto 40px;
}

.about-me__bio-link {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.about-me__bio-link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .about-me {
    width: calc(100% - (50px * 2));
    max-width: 1140px;
    
    padding: 90px 0;
  }
  
  .about-me__container {
    flex-direction: row;
    
    margin-top: 66px;
  }
  
  .about-me__photo {
    width: clamp(255px, 23vw, 270px);
    
    order: 2;
  }
  
  .about-me__bio {
    width: clamp(364px, 47.3vw, 600px);
    
    justify-content: space-between;
    
    order: 1;
  }
  
  .about-me__bio-name {
    font-size: clamp(40px, 5vw, 50px);
    line-height: clamp(40px, 5vw, 58px);
    letter-spacing: -1.2px;
    
    margin: 0 auto 16px;
  }
  
  .about-me__bio-details {
    font-size: clamp(12px, 1.5vw, 18px);
    line-height: clamp(18px, 2vw, 20px);
    
    margin: 16px auto 20px;
  }
  
  .about-me__bio-text {
    font-size: clamp(12px, 1.5vw, 14px);
    line-height: clamp(18px, 2vw, 22px);
    
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .about-me {
    width: calc(100% - (70px * 2));
    
    padding: 110px 0 125px;
  }
  
  .about-me__container {
    grid-column-gap: 270px;
  }
  
  .about-me__bio-name {
    letter-spacing: -2px;
    
    margin-top: 0;
    margin-bottom: 18px;
  }
  
  .about-me__bio-details {
    margin: 16px auto 26px;
  }
  
  .about-me__photo {
    min-height: 327px;
  }
}