.profile-link {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
}

.profile-link__link {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.profile-link__link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

.profile-link__icon {
  width: 30px;
  height: 30px;
  
  background-color: #313131;
  background-image: url('../../../images/account-pict.svg');
  background-repeat: no-repeat;
  background-position: center;
  
  border-radius: 6px;
}