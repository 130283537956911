.not-found {
  height: 100vh;
  
  display: grid;
  grid-template-rows: auto min-content;
  grid-row-gap: 30px;
  align-items: center;
}

.not-found__message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  transform: translate(0, 5%);
}

.not-found__status {
  font-size: clamp(80px, 19vw, 140px);
  font-weight: 400;
  line-height: normal;
  text-align: center;
  
  margin: 0 0 10px 0;
}

.not-found__description {
  font-size: clamp(12px, 2.5vw, 16px);
  font-weight: 400;
  line-height: normal;
  text-align: center;
  
  margin: 0;
}

.not-found__go-back-button {
  width: max-content;
  min-height: 15px;
  
  color: #4285f4;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  
  cursor: pointer;
  
  background-color: transparent;
  opacity: 1;
  
  margin: 0 auto 30px;
  border: none;
  padding: 0;
  
  transition: opacity .4s ease-out;
}

.not-found__go-back-button:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 768px) {
  .not-found {
    grid-row-gap: 60px;
  }
  
  .not-found__message-container {
    transform: translate(0, 20%);
  }
  
  .not-found__status {
    margin-bottom: 5px;
  }
  
  .not-found__go-back-button {
    font-size: 14px;
  
    margin-bottom: 222px;
  }
}

@media (min-width: 1280px) {
  .not-found {
    grid-row-gap: 50px;
  }
  
  .not-found__message-container {
    transform: translate(0, 10%);
  }
  
  .not-found__go-back-button {
    margin-bottom: 60px;
  }
}