.form {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  margin: 0 auto;
}

.form__error-message {
  color: #ee3465;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  
  margin: 16px auto;
}

.form__submit {
  width: 100%;
  
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  
  cursor: pointer;
  
  background-color: #4285f4;
  opacity: 1;
  
  border: none;
  border-radius: 3px;
  padding: 15px 0;
  
  transition: opacity .4s ease-out;
}

.form__submit:hover {
  opacity: .7;
  
  transition: opacity .2s ease-in;
}

.form__submit_disabled {
  cursor: default;
  
  background-color: #2f2f2f;
  opacity: .4;
}

.form__submit_disabled:hover {
  opacity: .4;
}

.form__submit_updated {
  cursor: default;
}

.form__submit_updated:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .form__submit {
    max-height: 45px;
    
    font-size: 14px;
  }
}