.title {
  box-sizing: border-box;
  
  font-size: clamp(18px, 4vw, 22px);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  
  margin: 0 0 40px 0;
  border-bottom: #dadada solid 1px;
  padding-bottom: 28px;
}

@media (min-width: 768px) {
  .title {
    letter-spacing: -0.88px;
    
    padding-bottom: 23px;
  }
}

@media (min-width: 1280px) {
  .title {
    letter-spacing: -0.88px;
    
    padding-bottom: 23px;
  }
}