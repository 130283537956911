.movies-card-list {
  width: calc(100% - 10px * 2);
  max-width: 708px;
  
  margin: 0 auto;
  padding: 40px 0 80px;
}

.movies-card-list__container {
  list-style: none;
  
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  row-gap: 16px;
  column-gap: 24px;
  
  margin: 0;
  padding: 0 0 80px 0;
}

@media (min-width: 480px) {
  .movies-card-list__container {
    grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  }
}

@media (min-width: 768px) {
  .movies-card-list {
    width: calc(100% - (30px * 2));
    max-width: 1140px;
    
    padding-top: 70px;
    padding-bottom: 10px;
  }
  
  .movies-card-list__container {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

@media (min-width: 1280px) {
  .movies-card-list__container {
    grid-row-gap: 32px;
    grid-column-gap: 28px;
    
    padding-bottom: 60px;
  }
}