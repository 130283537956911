.input-with-error-message__input {
  width: 100%;
  box-sizing: border-box;
  
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  
  background-color: transparent;
  
  margin-bottom: 6px;
  border: none;
  border-bottom: 1px solid #424242;
  padding: 0 0 8px 0;
  
  transition: none;
}

.input-with-error-message__input:focus {
  outline: none;
  border-bottom: 1px solid #8b8b8b;
  
  transition: none;
}

.input-with-error-message__input::placeholder {
  color: transparent;
}

.input-with-error-message__input_invalid {
  color: #ee3465;
}

.input-with-error-message__error-message {
  height: 24px;
  
  color: #ee3465;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  
  display: block;
}

@media (min-width: 768px) {
  .input-with-error-message__input {
    transition: all .2s ease-out;
  }
  
  .input-with-error-message__input:focus {
    transition: all .1s ease-in;
  }
}