.nav-tab {
  display: none;
}

.nav-tab__list {
  list-style: none;
  
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  
  margin: 0;
  padding: 0;
}

.nav-tab__link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;
  
  opacity: 1;
  
  transition: opacity .4s ease-out;
}

.nav-tab__link_active {
  font-weight: 500;
}

.nav-tab__link:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}

@media (min-width: 769px) {
  .nav-tab {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
  }
}